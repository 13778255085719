import React, { useRef, useState, useEffect } from "react"
import loadable from "@loadable/component"

// import Map from "../../components/map/Map"

import fitBounds from "../../components/map/utils/fitBounds"
import latLngDistanceCalculation from "../../components/map/utils/latLngDistanceCalculation"

import { graphql, navigate } from "gatsby"
import styled, { keyframes } from "styled-components"
import {
  Typography,
  IconButton,
  Grid,
  Select,
  FormControl,
  MenuItem
} from "@material-ui/core"
// import ReactMapboxGl, { ZoomControl, Marker, Cluster } from "react-mapbox-gl"
// import MapboxClient from "mapbox"
// import Geocoder from "react-mapbox-gl-geocoder"
// import "mapbox-gl/src/css/mapbox-gl.css"
import Parser from "html-react-parser"

// import app components
import MarkerIcon from "icons/marker.svg"
// import MarkerClusterIcon from "icons/markerCluster.svg"
// import LocateIcon from "icons/locate.svg"
import { BackgroundImage, Button, Edges, Spacer } from "components"
// import sortBy from "utils/sortBy"
import * as theme from "theme"

import WaveAltIcon from "icons/flipped-wave-sm.svg"
const Map = loadable(() => import("../../components/map/Map"))

const DealerLocator = props => {
  const {
    data: {
      page: {
        templateDealer: {
          dealerHero: { headline, image }
        }
      },
      allWpDealer: { nodes: dealers },
      wp: {
        themeOptions: {
          siteOptions: { news }
        }
      }
    }
  } = props

  // console.log({ dealers })

  const heroImage = image ? image : news.postBackgroundImage

  const [activeDealers, setActiveDealers] = useState([])
  const [hoveredLocation, setHoveredLocation] = useState(null)

  const [clickedItem, setClickedItem] = useState(null)
  // console.log({ clickedItem })
  // const handleLocate = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(async position => {
  //       if (position?.coords?.longitude && position?.coords?.latitude) {
  //         console.log({ position })
  //         // setRadius(500)
  //         // setLocation({
  //         //   longitude: position.coords.longitude,
  //         //   latitude: position.coords.latitude
  //         // })
  //       }
  //     })
  //   } else {
  //     console.error("Geolocation is not supported by this browser!")
  //   }
  // }

  const geojson = {
    type: "FeatureCollection",
    features:
      dealers &&
      dealers.map((dealer, index) => {
        return {
          id: index,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              dealer?.acf?.location?.longitude,
              dealer?.acf?.location?.latitude
            ]
          },
          properties: {
            id: dealer?.id,
            description: dealer?.title,
            phoneFormatted: dealer?.acf?.phone,
            phone: dealer?.acf?.phone,
            address: dealer?.acf?.location?.streetAddress,
            longitude: dealer?.acf?.location?.longitude,
            latitude: dealer?.acf?.location?.latitude,
            ...dealer?.acf
          }
        }
      })
  }

  const sidebarRef = useRef(null)

  const onMarkerClick = ({ event, properties, isCluster }) => {
    if (event?.features?.[0] && !isCluster) {
      const clickedDealer = dealers?.find?.(
        dealer => dealer.id === properties.id
      )

      setClickedItem(clickedDealer)
    }
  }

  return (
    <>
      <PageIntroContainer>
        <PageIntroOverlay />
        <PageIntroHero>
          <Edges size="cont">
            <Grid container>
              {/* <Grid item xs={12} md={2}></Grid> */}
              <Grid item xs={12} md={10}>
                <Typography variant="h1" children={headline} />

                {/* <Topbar>
                  <GeoLookupContainer component="div">
                    <LocateButton onClick={handleLocate}>
                      <LocateIcon color="#fff" />
                    </LocateButton>
                  </GeoLookupContainer>

                  <StyledButton children={`Reset location`} />
                </Topbar> */}
              </Grid>
            </Grid>
          </Edges>
        </PageIntroHero>
        {heroImage && (
          <HeroImageContainer>
            <BackgroundImage image={heroImage} />
          </HeroImageContainer>
        )}
      </PageIntroContainer>
      <HeroIntroText>
        <Edges size="cont">
          <WaveContainer>
            <WaveAltIcon />
          </WaveContainer>
        </Edges>
      </HeroIntroText>

      <OuterContainer>
        <Edges size="cont">
          <StyledGridContainer container>
            <Grid item xs={12} md={6} lg={5}>
              <Sidebar ref={sidebarRef}>
                {dealers && dealers.length > 0 ? (
                  dealers.map(dealer => {
                    return (
                      <Item
                        key={dealer.id}
                        dealer={dealer}
                        sidebarRef={sidebarRef}
                        clickedItem={clickedItem}
                        setHoveredLocation={setHoveredLocation}
                      />
                    )
                  })
                ) : (
                  <Spacer pt={40} pb={40}>
                    <Typography children={"No Results"} />
                  </Spacer>
                )}
              </Sidebar>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <MapContainer>
                <Map
                  // mapStyle="mapbox://styles/bareadvertising/ckigkhcw45of019nz4fcpgh3e"
                  onClick={onMarkerClick}
                  MarkerIcon={MarkerIcon}
                  geojson={geojson}
                  defaultLocation={{ latitude: 39.8283, longitude: -98.5795 }}
                />
              </MapContainer>
            </Grid>
          </StyledGridContainer>
        </Edges>
      </OuterContainer>
    </>
  )
}

const Item = ({ dealer, sidebarRef, clickedItem, setHoveredLocation }) => {
  const itemRef = useRef(null)

  useEffect(() => {
    if (clickedItem?.id === dealer?.id) {
      const relativeOffsetTop =
        itemRef?.current?.offsetTop - sidebarRef?.current?.offsetTop

      relativeOffsetTop &&
        sidebarRef?.current?.scrollTo?.({
          top: relativeOffsetTop,
          left: 0,
          behavior: "smooth"
        })
    }
  }, [clickedItem])

  const productCount = dealer.dealerCategories.nodes.length

  return (
    <SidebarItem
      key={dealer.id}
      ref={itemRef}
      onMouseEnter={() => setHoveredLocation(dealer)}
      onMouseLeave={() => setHoveredLocation(null)}
    >
      <Spacer mb={10}>
        <TitleText>DEALER NAME</TitleText>
      </Spacer>
      <Spacer mb={10}>
        <SidebarTitle
          children={dealer.title && Parser(dealer.title)}
          variant="caption"
        />
      </Spacer>
      <Spacer mb={10}>
        {productCount > 0 && (
          <Spacer mb={10}>
            <TitleText>PRODUCTS</TitleText>
          </Spacer>
        )}
        {dealer.dealerCategories.nodes &&
          dealer.dealerCategories.nodes.map((o, i) => {
            return (
              <CaptionText key={i}>
                {o.name}
                {productCount === i + 1 ? "" : ", "}
              </CaptionText>
            )
          })}
      </Spacer>
      <Spacer mb={10}>
        <TitleText>ADDRESS</TitleText>
      </Spacer>
      {dealer.acf?.location?.streetAddress && (
        <Spacer mb={10}>
          <Typography
            children={Parser(
              dealer.acf.location.streetAddress
                .split(", ")
                .filter(s => !!s)
                .join(", ")
            )}
            variant="caption"
            color="inherit"
          />
        </Spacer>
      )}
      {dealer.acf?.phone && (
        <FlexSpacer mb={10}>
          <strong>T</strong>
          <Spacer mr={5} />
          <a href={`tel:${dealer.acf?.phone}`}>
            <Typography
              children={Parser(dealer.acf.phone)}
              variant="caption"
              color="inherit"
            />
          </a>
        </FlexSpacer>
      )}
      {dealer.acf?.email && (
        <FlexSpacer mb={10}>
          <strong>E</strong>
          <Spacer mr={5} />
          <a href={`mailto:${dealer.acf?.email}`}>
            <Typography
              children={Parser(dealer.acf.email)}
              variant="caption"
              color="inherit"
            />
          </a>
        </FlexSpacer>
      )}

      <QuoteButton
        children="Request a Quote"
        variant="outlined"
        color="secondary"
        onClick={() =>
          navigate(`/request-quotation`, {
            state: { dealer: dealer.title }
          })
        }
      />
    </SidebarItem>
  )
}

/**
 * PageIntro Styles
 */
const pageIntroBaseHeight = 295
const PageIntroContainer = styled.div`
  height: ${pageIntroBaseHeight - 45}px;
  padding: ${({ border }) => (border ? "20px" : 0)};
  position: relative;
  z-index: 4;

  @media (min-width: 600px) {
    height: ${pageIntroBaseHeight}px;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: ${pageIntroBaseHeight}px;
    background: radial-gradient(
      33.94% 50% at 77.5% 120.75%,
      #000000 0%,
      rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(
      28.09deg,
      #000000 14.29%,
      rgba(0, 0, 0, 0) 39.6%
    );
  }
`

const PageIntroOverlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  position: absolute;
  width: 100%;
  height: 110px;
  bottom: 0px;
  left: 0px;
  z-index: 1;

  @media (min-width: 768px) {
    height: ${pageIntroBaseHeight - 30}px;
  }
`

const PageIntroHero = styled.div`
  align-items: center;
  background: linear-gradient(28.09deg, #000000 14.29%, rgba(0, 0, 0, 0) 39.6%);
  backdrop-filter: blur(12px);
  bottom: 0;
  color: white;
  display: flex;
  height: ${pageIntroBaseHeight - 60}px;
  left: 0;
  padding: 65px 0px 40px;
  position: absolute;
  width: 100%;
  z-index: 1;

  h1 {
    margin-bottom: 12px;
  }

  @media (min-width: 600px) {
    padding: 95px 0px 40px;
    height: ${pageIntroBaseHeight - 30}px;
  }
`

const HeroImageContainer = styled.div`
  position: relative;
  height: ${pageIntroBaseHeight - 60}px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: ${pageIntroBaseHeight}px;
  }
`

const HeroIntroText = styled.div`
  background: radial-gradient(
    17.98% 717.28% at 14.84% -12.35%,
    #000000 41.89%,
    #252525 100%
  );
  color: white;
  padding: 0px;
  position: relative;
  width: 100%;
  z-index: 9;

  @media (min-width: 768px) {
    z-index: 3;
  }
`

const WaveContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 0px;
  top: -5px;

  svg {
    max-width: 100%;
    width: 100%;
  }

  @media (min-width: 600px) {
    top: 0px;
  }
`
/**
 * END PageIntro Styles
 */

const OuterContainer = styled.div`
  background: radial-gradient(
    69.12% 69.29% at 12.66% -27.53%,
    #000000 41.89%,
    #252525 100%
  );
  position: relative;
  z-index: 1;
  padding-top: 40px;

  @media (min-width: 768px) {
    padding-top: 60px;
  }

  @media (min-width: 960px) {
    padding-top: 0px;
  }
`

const smoothBounce = keyframes`
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-10px); }
    100% { transform: translateY(0); }
`

const StyledButton = styled(Button)`
  background: ${theme.colors.text.light};
  border: 2px solid ${theme.colors.text.light};
  color: #000;
  font-size: 13px;
  margin-left: 15px;
  min-width: 150px;
  padding: 8px 15px;

  &:hover {
    opacity: 0.9;
  }

  @media (min-width: 768px) {
    margin-left: 15px;
  }

  @media (min-width: 1280px) {
    min-width: 200px;
  }
`

const StyledSelect = styled(Select)`
  background: #000;
  border: 2px solid ${theme.colors.tertiary};
  border-radius: 25px;
  color: #fff;
  min-width: 150px;
  padding: 5px 20px;

  &:before {
    border: 0px;
  }

  &:after {
    border: 0px;
  }

  &:hover {
    &:not(.Mui-disabled):before {
      border: 0px;
    }
  }

  .MuiSelect-icon {
    font-size: 32px;
    right: 15px;
    fill: ${theme.colors.tertiary};
    top: calc(50% - 16px);
  }

  &.Mui-disabled {
    border: 2px solid ${theme.colors.text.light};
    color: rgba(255, 255, 255, 0.3);

    .MuiSelect-icon {
      fill: ${theme.colors.text.light};
    }
  }

  @media (min-width: 768px) {
    margin-left: 15px;
  }

  @media (min-width: 960px) {
  }

  @media (min-width: 1280px) {
    min-width: 200px;
  }
`

const Topbar = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin-top: 30px;
  width: 100%;

  @media (min-width: 768px) {
  }

  @media (min-width: 1320px) {
    flex-flow: row;
  }
`

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`

const CaptionText = styled.span`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
`

const FlexSpacer = styled(Spacer)`
  display: flex;
  overflow: hidden;
`

const StyledGridContainer = styled(Grid)`
  flex-flow: column-reverse wrap;

  .mapboxgl-popup {
    max-width: 250px;
  }

  .marker-container {
    display: flex;
    justify-content: center;

    &:hover {
      .marker-tooltip {
        display: block;
      }
    }
  }

  .marker-bounce {
    > svg {
      animation: ${smoothBounce} 2s ease infinite;
    }
    > .marker-tooltip {
      display: block;
      animation: ${smoothBounce} 2s ease infinite;
    }
  }

  @media (min-width: 960px) {
    flex-flow: row;
  }
`

const GeoLookupContainer = styled(Typography)`
  display: flex;
  align-items: center;
  background: transparent;
  position: relative;
  margin-bottom: 15px;
  min-width: 50%;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    min-width: 40%;
    margin-bottom: 0px;
  }

  @media (min-width: 960px) {
  }

  @media (min-width: 1280px) {
    min-width: 50%;
  }
`

const LocateButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
`

const GeocoderContainer = styled.div`
  width: 100%;

  input {
    background: ${theme.colors.primary || "transparent"};
    border: 2px solid ${theme.colors.tertiary || "black"};
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    height: 25px;
    padding: 8px 12px 8px 60px;
    transition: background-color 300ms;
    width: 100%;

    &:hover {
      background: #333;
    }
  }

  .MuiInput-underline {
    &:before {
      border-bottom: 0px;
    }

    &:hover {
      box-shadow: none;
      border-bottom: 0px;

      &:before,
      &:after {
        box-shadow: none;
        border-bottom: 0px;
      }
    }
  }

  .react-geocoder-results {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    height: auto;
    z-index: 999;
    background: #fff;
    color: ${theme.colors.primary};
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  }

  .react-geocoder-item {
    padding: 8px 12px;
    transition: ease all 0.2s;
    cursor: pointer;

    &:hover {
      background: #f2f2f2;
    }
  }
`

const Sidebar = styled.div`
  background: transparent;
  border-top: 1px solid #dedede;
  color: white;
  height: 250px;
  margin-top: 35px;
  max-width: 100%;
  overflow: auto;
  padding-top: 15px;
  padding-bottom: ${props => (props.hasPagination ? "36px" : 0)};
  position: relative;
  width: 100%;

  @media (min-width: 960px) {
    border-top: none;
    height: 500px;
    margin-top: 0px;
    max-width: 100%;
    overflow: auto;
    order: 1;
    padding-left: ${(920 / 12) * 2 - 20}px;
    width: 100%;
  }

  @media (min-width: 1280px) {
    padding-left: ${(1220 / 12) * 2 - 20}px;
  }
`

const SidebarItem = styled.div`
  border-bottom: 1px solid ${theme.colors.background.dark};
  padding: 20px;
  transition: ease all 0.2s;
  width: 100%;

  a {
    color: white;
  }
`

const SidebarTitle = styled(Typography)`
  && {
    font-size: 18px;
  }
`

const MapContainer = styled.div`
  height: 300px;
  position: relative;
  width: 100%;

  .mapboxgl-map {
    height: 100%;
  }

  @media (min-width: 960px) {
    height: 500px;
    order: 2;
    width: 100%;
  }
`

// const StyledMarkerIcon = styled(MarkerIcon)`
//   width: 36px !important;
//   height: 36px !important;
//   cursor: pointer;

//   path {
//     fill: ${props =>
//       props.variant === "location"
//         ? theme.colors.secondary
//         : theme.colors.primary};
//   }
// `

// const StyledMarkerClusterIcon = styled(MarkerClusterIcon)`
//   width: 36px !important;
//   height: 36px !important;
//   cursor: pointer;

//   path {
//     fill: ${theme.colors.primary};
//   }
// `

// const Tooltip = styled.div`
//   position: absolute;
//   bottom: 100%;
//   background: #fff;
//   border-radius: 5px;
//   padding: 5px;
//   z-index: 99;
//   > span {
//     white-space: nowrap;
//   }
//   display: none;
// `

const QuoteButton = styled(Button)`
  && {
    margin-top: 15px;
    padding: 6px 28px;
  }
`

export const CollectionQuery = graphql`query DealerTestTemplate($id: String!) {
  page: wpPage(id: {eq: $id}) {
    templateDealer {
      dealerHero {
        headline
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  wp {
    themeOptions {
      siteOptions {
        news {
          headerTitle
          postBackgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
  allWpDealer(filter: {acf: {location: {streetAddress: {ne: ""}}}}) {
    nodes {
      id
      databaseId
      title
      dealerCategories {
        nodes {
          name
        }
      }
      acf {
        location {
          latitude
          longitude
          streetAddress
        }
        phone
        email
      }
    }
  }
}
`

export default DealerLocator
